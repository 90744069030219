<template>
    <div class="mTabContainer">

        <!-- Tabs -->
        <ul class="mTabs">

            <!-- Tab -->
            <li class="mTab" v-for="(tabName, index) in tabs" :key="'mTab-' + id + tabName"
                @click="changeTab(activeTab, tabName, index)">
                <div class="mTabText">
                    <div v-if="tabName === activeTab" :class="tabAnimations[tabName] ? 'mTabActive' : ''"
                         style="color: #428BFF">
                        {{ tabName }}
                    </div>
                    <div v-else :class="tabAnimations[tabName] ? 'mTabInactive' : ''" style="color: #929daf">
                        {{ tabName }}
                    </div>
                </div>
            </li>

        </ul>

        <!-- Slider -->
        <div>
            <div class="slider" :style="'width: ' + (100 / Object.keys(tabs).length) + '%'" :id="'slider-' + id">
                <div class="indicator"/>
            </div>
        </div>

        <!-- Tab Content -->
        <div class="mTabContent" v-for="tabName in tabs" v-if="tabName === activeTab"
             :class="contentAnimation ? 'mTabContentAnimation' : ''">
            <slot :name="tabName">
            </slot>
        </div>

    </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
export default {
    name: "m-tabs",
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            default: null
        }
    },
    data() {
        return {
            contentAnimation: false,
            tabAnimations: {},
            activeTab: null,
            activeTabIndex: null,
            tabSwitchTime: 300,
            tabCount: 1,
            id: uuidv4()
        }
    },
    created() {
        this.tabs.forEach(tabName => {
            this.tabAnimations[tabName] = false;
        });
        this.tabCount = this.tabs.length;
        let index = this.tabs.findIndex(tab => tab === this.defaultTab);
        this.activeTabIndex = ~index ? index : 0;
    },
    mounted() {
        let slider = document.getElementById('slider-' + this.id);
        let dest = slider.offsetWidth * this.activeTabIndex;
        slider.style.left = `${(dest / slider.parentElement.offsetWidth) * 100}%`;
    },
    methods: {
        changeTab(oldTabName, tabName, index) {
            if (this.activeTabIndex !== index) {
                this.contentAnimation = true;
                this.tabAnimations[tabName] = true;
                this.$emit('beforeTabChange', oldTabName, tabName);
                this.moveSlider(tabName, index);
                this.activeTabIndex = index;
                setTimeout(() => {
                    this.$emit('tabChange', oldTabName, tabName);
                }, this.tabSwitchTime);
            }
        },
        moveSlider(tabName, index) {
            const slider = document.getElementById('slider-' + this.id);
            const startPos = slider.offsetLeft;
            const dest = slider.offsetWidth * index;
            const dist = Math.abs(dest - startPos);
            const animationDuration = this.tabSwitchTime;

            let startTime = null;

            const animate = timestamp => {
                if (!startTime) startTime = timestamp;
                const elapsedTime = timestamp - startTime;

                if (elapsedTime < animationDuration) {
                    const progress = elapsedTime / animationDuration;
                    const direction = dest > startPos ? 1 : -1;
                    const newPos = startPos + (progress * dist * direction);

                    slider.style.left = newPos + 'px';
                    requestAnimationFrame(animate);
                } else {
                    slider.style.left = `${(slider.offsetWidth * index / slider.parentElement.offsetWidth) * 100}%`;
                }
            };

            requestAnimationFrame(animate);
        }
    },
    watch: {
        activeTabIndex() {
            if (this.activeTabIndex === null || !this.tabs[this.activeTabIndex]) {
                this.activeTab = null;
            }
            this.activeTab = this.tabs[this.activeTabIndex];
        },
        value() {
            let index = this.tabs.findIndex(tab => tab === this.value);
            if (~index) {
                this.changeTab(this.activeTab, this.value, index);
                this.$emit('input', null);
            }
        }
    }
}
</script>

<style scoped>
.mTabContainer {
    display: flex;
    flex-direction: column;
}
.mTabs {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin: 16px 0 10px 0;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}
.mTab {
    box-sizing: border-box;
    flex: 1;
    width: 25%;
    padding: 0 10px;
    text-align: center;
}
.mTabText {
    padding: 5px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.15;
}
.mTabActive {
    animation: mTabColorChange 0.6s;
}
.mTabInactive {
    animation: mTabColorChange 0.6s reverse;
}
@keyframes mTabColorChange {
    0% { color: #929daf; }
    100% { color: #428BFF; }
}
.slider {
    position: relative;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
    margin-bottom: 15px;
}
.indicator {
    position: relative;
    width: 50px;
    max-width: 100%;
    margin: 0 auto;
    height: 4px;
    background: #428BFF;
    border-radius: 0.25rem;
    transition: all 0.3s ease-in-out;
}
.mTabContent {
    width: 100%;
    flex: 1;
    overflow: auto;
}
.mTabContentAnimation {
    animation: mTabContentAnimation .6s;
}
@keyframes mTabContentAnimation {
    from {
        opacity: 0;
        transform: translateY(5%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

</style>