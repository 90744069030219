<template>
    <div>
        <v-client-table ref="dTable"
                        style="width: 100%"
                        :data="items"
                        :columns="tableColumns"
                        :options="tableOptions"
                        :theme="theme"
                        @sorted="columnSorted"
                        :class="showFilter === true ? '' : 'hideFilter'"
                        class="dataTable dataTableOverride"
                        @row-click="rowClicked">
            <template v-for="tableColumn in tableColumns" :slot="tableColumn" slot-scope="props">
                <slot :name="tableColumn" :props="props.row">{{props.row[tableColumn]}}</slot>
            </template>
            <template v-for="tableColumn in tableColumns" :slot="'h__' + tableColumn">
                <slot :name="'h__' + tableColumn">
                        {{tableOptions.headings[tableColumn] || tableColumn}}
                </slot>
            </template>
            <div slot="afterTable" style="text-align: center;">
                <div style="display:flex; justify-content: center" v-if="itemCount > itemsPerPage">
                    <b-pagination v-model="currentPage"
                                  :total-rows="itemCount"
                                  :per-page="itemsPerPage"
                                  :hide-ellipsis="true"
                                  class="paginationOverride"
                                  :hide-goto-end-buttons="true"/>
                </div>
                <div v-if="itemCount === 0">0 records</div>
                <div v-else-if="itemCount === 1">1 record</div>
                <div v-else style="font-size: 15px">{{'Showing ' + (1 + (currentPage-1) * itemsPerPage) + ' to '}}{{((itemsPerPage*currentPage) < itemCount ? (itemsPerPage*currentPage): itemCount) + ' of '+ itemCount + ' records'}}</div>
            </div>
            <div v-if="showFilter === true" slot="afterFilter">
                <b-input placeholder="Search query" style="margin-left: 5px;" v-model="filterText"></b-input>
            </div>
            <div slot="afterLimit" style="text-align: center; display: flex">
                <span style="margin-top: 7px; font-size: 14px; padding-right: 3px">Records:&nbsp;</span>
                <b-input-group>
                    <b-form-select v-model="itemsPerPage"
                                   :options="itemPerPageOptions"
                                   @change="itemsPerPageChanged"
                                   style="cursor:pointer; padding-left:15px"
                                   class="input-height"/>
                </b-input-group>
            </div>
        </v-client-table>
    </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2';
import Vue from "vue";

Vue.use(ClientTable)

export default {
    name: "m-client-table",
    props: {
        itemPerPageOptions: {
            type: Array,
            default: [
                {text: 10, value: 10},
                {text: 25, value: 25},
                {text: 50, value: 50},
                {text: 100, value: 100},
            ]
        },
        itemCount: {
            type: Number,
            required: true
        },
        items: {
            type: Array,
            default: []
        },
        tableColumns: {
            type: Array,
            required: true
        },
        tableOptions: {
            type: Object,
            required: true
        },
        buttonColumnClicked: {
            type: Function,
        },
        startingPage: {
            type: Number,
            default: 1
        },
        startingItemsPerPage: {
            type: Number,
            default: 25
        },
        showFilter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            theme: 'bootstrap4',
            filterText: '',
            currentPage: 1,
            itemsPerPage: 25,
            columnInfo: {
                column: '',
                ascending: true
            },
            timerId: null,
        }
    },
    created() {
        this.currentPage = this.startingPage;
        this.itemsPerPage = this.startingItemsPerPage;
    },
    methods: {
        rowClicked(data) {
            this.$emit('row-click', data)
        },
        itemsPerPageChanged() {
            this.$refs.dTable.setLimit(this.itemsPerPage);
            this.getItemsByPage();
        },
        columnSorted(e) {
            this.columnInfo.column = e.column;
            this.columnInfo.ascending = e.ascending;
            this.getItemsByPage();
        },
        getItemsByPage() {
            this.$emit('getItemsByPage', {
                page: this.currentPage,
                count: this.itemsPerPage,
                text: this.filterText ,
                ascending: this.columnInfo
            })
        },
    },
    watch: {
        filterText() {
            clearTimeout(this.timerId)
            this.timerId = setTimeout(() => {
                this.currentPage = 1;
                this.getItemsByPage();
            }, 350);
        },
        currentPage() {
            this.getItemsByPage();
        }
    }
}
</script>

<style scoped>
    .paginationOverride >>> .page-link {
        color: white;
    }
    .dataTableOverride >>>.VuePagination__count {
        display: none;
    }
    .dataTableOverride >>>.VuePagination__pagination {
        display: none;
    }
    .dataTableOverride >>>.VueTables__limit-field {
        display: none;
    }
    .dataTableOverride >>>.VueTables__search__input {
        display: none;
    }
    .hideFilter >>>.VueTables__search {
        display: none;
    }
</style>