<template>
    <div>

        <!-- Add Case Modal -->
        <b-modal no-close-on-backdrop scrollable title="Add Case" class="modal-dark" v-model="addCaseModal">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Case Description</label>
                        <textarea autofocus
                                  class="form-control rounded-0 input-height"
                                  rows="3"
                                  maxlength="200"
                                  type="text"
                                  @keyup.enter="submitCase"
                                  v-model="addCaseDesc"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="addCaseModal = false; addCaseDesc = ''">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" :disabled="addCaseDesc === ''" @click="submitCase">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!-- Edit Case Modal -->
        <b-modal no-close-on-backdrop scrollable title="Edit Case" class="modal-dark" v-model="editCaseModal">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Case Description</label>
                        <textarea class="form-control rounded-0 input-height"
                                  rows="3"
                                  maxlength="200"
                                  type="text"
                                  @keyup.enter="updateCase"
                                  v-model="editCase.description"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Case Status</label>
                        <b-form-select v-model="editCase.status" :options="statusOptions"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <div class="w-100 d-flex justify-content-between">
                    <router-link :to="{ name: 'Case', params: {caseId: editCase._id}}">
                        <b-button size="sm" variant="info">
                            Edit Case Reports
                        </b-button>
                    </router-link>
                    <div>
                        <b-button size="sm" variant="secondary" @click="editCaseModal = false" style="margin-right: 10px">
                            Cancel
                        </b-button>
                        <b-button size="sm" variant="primary" :disabled="editCase.description === ''" @click="updateCase">
                            Submit
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>

        <!-- Search Modal -->
        <b-modal no-close-on-backdrop scrollable title="Search" class="modal-dark" v-model="searchModal" size="xl" @close="emptySearchModal">
            <div style="min-height: 500px">
                <b-form-group>
                    <label>Select Template</label>
                    <b-form-select v-model="searchTemplate"
                                   :options="[{value: '', text: ''}].concat(templates.map(_ => { return {text: _.name, value: _._id}}))"
                                   style="cursor:pointer;"
                                   @change="templateSelected"
                                   class="input-height"/>
                </b-form-group>
                <b-form-group>
                    <div style="display: flex; justify-content: space-between">
                        <label>Searchable Fields</label>
                        <div v-if="searchFields">
                            <b-button size="sm" style="margin-bottom: 5px; margin-right: 5px;" @click="searchTemplateFields = searchFields">Select All Fields</b-button>
                            <b-button size="sm" style="margin-bottom: 5px;" @click="searchTemplateFields = []">Deselect All Fields</b-button>
                        </div>
                    </div>
                    <multiselect v-model="searchTemplateFields"
                                 :options="searchFields"
                                 :multiple="true"
                                 label="label"
                                 track-by="label"/>
                </b-form-group>
                <b-form-group>
                    <b-row>
                        <b-col>
                            <div>Start Date <fa-icon :icon="['fas', 'calendar']"/></div>
                            <date-picker :config="dateOptions" class="input-height" v-model="startDate"/>
                        </b-col>
                        <b-col>
                            <div>End Date <fa-icon :icon="['fas', 'calendar']"/></div>
                            <date-picker :config="dateOptions" class="input-height" v-model="endDate"/>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group>
                    <label>Query</label>
                    <b-form-input v-model="reportSearchText" class="input-height" placeholder="Text To Search"/>
                </b-form-group>
                <div v-if="searchingIcon === true" class="d-flex justify-content-center">
                    <fa-icon :icon="['fas', 'rotate-right']" fixed-width spin size="2x" style="margin-bottom: 10px;" title="Searching For Results"/>
                </div>
                <b-card>
                    <div slot="header">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 style="margin-bottom: 0"><b>Search Results</b></h5>
                        </div>
                    </div>
                    <m-client-table :itemPerPageOptions="itemPerPageOptions"
                                    :itemCount="reportResultCount"
                                    :tableColumns="searchResultOptions.columns"
                                    :tableOptions="searchResultOptions"
                                    :items="searchResults"
                                    :showFilter="false"
                                    @getItemsByPage="getReportResultsByPage">
                        <template v-slot:reportNumber="props">
                            <router-link target="_blank" :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}" style="color: inherit">
                                {{props.props.reportNumber}}
                            </router-link>
                        </template>
                        <template v-slot:user="props">
                            {{props.props.assigned_user.name}}
                        </template>
                        <template v-slot:created_date="props">
                            <div>{{ new Date(props.props.created_date).toLocaleString() }}</div>
                        </template>
                    </m-client-table>
                </b-card>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="emptySearchModal">
                    Close
                </b-button>
                <b-button size="sm" variant="primary" @click="searchReports">
                    Search
                </b-button>
            </template>
        </b-modal>

        <!--Main Page-->
        <b-row>
            <!-- Cases Table -->
            <b-col lg="6">
                <b-card style="height: calc(100vh - 200px);" no-body>
                    <div slot="header">
                        <div class="d-flex w-100 justify-content-between align-items-baseline">
                            <h5 style="margin-bottom: 0"><b>All Cases</b></h5>
                            <div v-if="user.role !== 'user'" class="d-flex justify-content-end">
                                <b-checkbox v-if="userIsPREA === true" size="sm" v-model="onlyPREA" style="padding: 0 10px 0 0;" @input="filterPREACases">PREA</b-checkbox>
                                <fa-icon :icon="['fas', 'magnifying-glass']" size="lg" @click="openReportSearchModal" title="Search Reports" style="margin-right: 10px; margin-top: 1px; cursor: pointer;"/>
                                <fa-icon :icon="['fas', 'square-plus']" size="lg" @click="addCaseModal = true" title="Create Case" style="margin-top: 1px; cursor: pointer;"/>
                            </div>
                        </div>
                    </div>
                    <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                        <m-client-table :itemPerPageOptions="itemPerPageOptions"
                                 :itemCount="caseCount"
                                 :tableColumns="user.role === 'user' ? caseOptions.columnsNoEdit : caseOptions.columns"
                                 :tableOptions="caseOptions"
                                 :items="cases"
                                 :buttonColumnClicked="editCaseFunc"
                                 @getItemsByPage="getCasesByPage">
                            <template v-slot:h__edit class="float-right" v-if="user.role !== 'user'">
                                <span style="float: right; margin-bottom: 0">Edit</span>
                            </template>
                            <template v-slot:edit="props" v-if="user.role !== 'user'">
                                <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; width: 29px"
                                          @click="editCaseFunc(props.props)"
                                          size="sm"
                                          title="Edit Case Info"
                                          variant="dark">
                                    <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style="margin-left: -2px;"/>
                                </b-button>
                            </template>
                            <template v-slot:caseNumber="props">
                                <router-link :to="{ name: 'Case', params: {caseId: props.props._id}}"
                                             style="color: inherit"
                                             title="Go To Case">
                                    {{ props.props.caseNumber }}
                                </router-link>
                            </template>
                        </m-client-table>
                    </div>
                </b-card>
            </b-col>

            <!--Case Requests Waiting-->
            <b-col lg="6">
                <b-card header-tag="header" style="height: calc(100vh - 200px);" no-body>
                    <div slot="header" style="width: 100%" class="d-flex align-items-center">
                        <h5 style="margin-bottom: 0"><b>Reports To Review</b></h5>
                    </div>
                    <div v-if="Array.isArray(needReviewTable.items) && needReviewTable.items.length < 1" style="width: 100%; height: 100%;" class="d-flex justify-content-center align-items-center">
                        <i>no report requests</i>
                    </div>
                    <div v-else-if="!needReviewTable.items" style="width: 100%; height: 100%;" class="d-flex justify-content-center align-items-center">
                        <fa-icon :icon="['fas', 'spinner']" spin style="margin-right: 5px"/>
                        <i>loading</i>
                    </div>
                    <v-client-table v-else class="dataTable" :columns="needReviewTable.tableColumns" :data="needReviewTable.items"
                                    :options="needReviewTable.tableOptions" :theme="'bootstrap4'" style="padding: 20px; margin: 0; width: 100%">
                        <div slot="reportNumber" slot-scope="props">
                            <router-link :to="{ name: 'Case', params: {caseId: props.row.caseId, reportId: props.row._id}}" style="color: inherit" title="Go To Report">
                                {{ props.row.reportNumber }}
                            </router-link>
                        </div>
                    </v-client-table>
                </b-card>
            </b-col>

        </b-row>
    </div>
</template>

<script>
import Report from "@/services/report";
import Cases from "@/views/expansion_modules/reports/case_components/Cases";
import datePicker from "vue-bootstrap-datetimepicker";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Multiselect from 'vue-multiselect';
import MClientTable from "@/components/m-client-table";
import {ClientTable} from 'vue-tables-2';
import Vue from "vue";
Vue.use(ClientTable)

export default {
    name: "SupervisorView",
    components: {
        Cases,
        MClientTable,
        ClientTable,
        Multiselect,
        datePicker
    },
    data() {
        return {
            needReviewTable: {
                items: null,
                tableColumns: ["reportNumber", "status", "assigned_user.name"],
                tableOptions: {
                    alwaysShowPerPageSelect: true,
                    headings: {
                        reportNumber: "Report Number",
                        status: 'Stauts',
                        "assigned_user.name": 'User'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {chunk: 5, edge: false, nav: 'scroll'},
                    perPage: 5,
                    skin: 'table table-striped table-hover',
                    customSorting: {
                        reportNumber: function (ascending) {
                            return function (a, b) {
                                let aArray = a.reportNumber.split('-');
                                let bArray = b.reportNumber.split('-');
                                let aReport = aArray[1] + aArray[2];
                                let bReport = bArray[1] + bArray[2];
                                if (parseFloat(aReport) < parseFloat(bReport)) {
                                    return ascending === true ? -1 : 1;
                                } else if (parseFloat(aReport) > parseFloat(bReport)) {
                                    return ascending === true ? 1 : -1;
                                }
                                return 0;
                            }
                        }
                    }
                },
            },
            cases: [],
            addCaseModal: false,
            editCaseModal: false,
            searchModal: false,
            addCaseDesc: '',
            editCase: {},
            searchTemplate: '',
            searchTemplateFields: [],
            reportSearchText: '',
            reportResultCount: 0,
            searchFields: [],
            searchResults: [],
            templates: [],
            caseCount: 0,
            itemPerPageOptions: [
                {text: 10, value: 10},
                {text: 25, value: 25},
                {text: 50, value: 50},
                {text: 100, value: 100},
            ],
            searchingIcon: false,
            statusOptions: [
                'In Progress',
                'On-Hold',
                'Approved',
                'Closed'
            ],
            caseOptions: {
                columns: ['caseNumber', 'description', 'status', 'edit'],
                columnsNoEdit: ['caseNumber', 'description', 'status'],
                sortable: ['caseNumber', 'description', 'status'],
                headings: {
                    caseNumber: 'Case Number',
                    description: 'Description',
                    status: 'Status',
                    edit: 'Edit'
                },
                alwaysShowPerPageSelect: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {chunk: 5, edge: false, nav: 'scroll'},
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    caseNumber: function (ascending) {
                        return function (a, b) {
                            let aArray = a.caseNumber.split('-');
                            let bArray = b.caseNumber.split('-');
                            let aCase = aArray[1] + aArray[2];
                            let bCase = bArray[1] + bArray[2];
                            if (parseInt(aCase) < parseInt(bCase)) {
                                return ascending === true ? -1 : 1;
                            } else if (parseInt(aCase) > parseInt(bCase)) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    }
                }
            },
            searchResultOptions: {
                columns: ['reportNumber', 'user', 'created_date', 'caseDescription'],
                sortable: ['reportNumber', 'user', 'created_date', 'caseDescription'],
                filterable: ['reportNumber', 'user', 'created_date', 'caseDescription'],
                orderBy: {column: 'reportNumber', ascending: true},
                headings: {
                    reportNumber: 'Report Number',
                    user: 'User',
                    created_date: 'Created Date',
                    caseDescription: 'Description'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {chunk: 5, edge: false, nav: 'scroll'},
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    reportNumber: function (ascending) {
                        return function (a, b) {
                            let aArray = a.reportNumber.split('-');
                            let bArray = b.reportNumber.split('-');
                            let aReport = aArray[1] + aArray[2];
                            let bReport = bArray[1] + bArray[2];
                            if (parseFloat(aReport) < parseFloat(bReport)) {
                                return ascending === true ? -1 : 1;
                            } else if (parseFloat(aReport) > parseFloat(bReport)) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    },
                    created_date: function (ascending) {
                        return function (a, b) {
                            if (new Date(a.created_date).valueOf() < new Date(b.created_date).valueOf()) {
                                return ascending === true ? -1 : 1;
                            } else if (new Date(a.created_date).valueOf() > new Date(b.created_date).valueOf()) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    },
                    user: function (ascending) {
                        return function (a, b) {
                            if (a.assigned_user.name.toUpperCase() < b.assigned_user.name.toUpperCase()) {
                                return ascending === true ? 1 : -1;
                            } else if (a.assigned_user.name.toUpperCase() > b.assigned_user.name.toUpperCase()) {
                                return ascending === true ? -1 : 1;
                            }
                            return 0;
                        }
                    }
                }
            },
            theme: 'bootstrap4',
            user: {
                email: null,
                name: null,
                role: null,
                sub: null
            },
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: true,
            },
            startDate: '',
            endDate: '',
            onlyPREA: false,
            userIsPREA: false,
        }
    },
    async created() {
        await this.getCasesByPage({
            page: 1,
            count: 25,
            text: '',
            ascending: {
                column: 'caseNumber',
                ascending: true
            },
            restrict: false
        });
        await this.getReportsWithStatus();
    },
    methods: {
        filterPREACases(checked) {
            if (checked === true) {
                this.getCasesByPage({page: 1, count: 25, text: '', filterPREA: true, ascending: {column: 'caseNumber', ascending: true}});
            } else {
                this.getCasesByPage({page: 1, count: 25, text: '', ascending: {column: 'caseNumber', ascending: true}});
            }
        },
        getReportsWithStatus() {
            Report.getReportsWithStatus('Pending Approval').then(response => {
                this.needReviewTable.items = response.data;
            }).catch(() => {
                this.$mToast({
                    title: 'Error getting reports',
                    style: 'error'
                });
            });
        },
        emptySearchModal() {
            this.searchModal = false;
            this.searchTemplate = '';
            this.searchTemplateFields = [];
            this.reportSearchText = '';
            this.reportResultCount = 0;
            this.searchFields = [];
            this.searchResults = [];
        },
        templateSelected(id) {
            this.searchTemplateFields = [];
            this.searchFields = [];
            if (id !== '') {
                Report.getTemplateFields(id).then(response => {
                    this.searchFields = response.data;
                });
            }
        },
        searchReports() {
            this.getReportResultsByPage({page: 1, count: 25, text: '', filterPREA: false, ascending: {column: 'reportNumber', ascending: true}});
        },
        openReportSearchModal() {
            this.getTemplates();
            this.searchModal = true;
        },
        getTemplates() {
            Report.getTemplates().then(response => {
                this.templates = response.data;
            });
        },
        editCaseFunc(row) {
            this.editCase = row;
            this.editCaseModal = true;
        },
        updateCase() {
            if (this.editCase.description !== '') {
                Report.putCase(this.editCase._id, this.editCase).then(() => {
                    this.getCasesByPage({page: 1, count: 25, text: '', ascending: {column: 'caseNumber', ascending: true}});
                    this.editCaseModal = false;
                    this.$mToast({
                        title: "Case Updated" ,
                        style: 'success',
                    });
                });
            }
        },
        submitCase() {
            if (this.addCaseDesc !== '') {
                Report.postCase({description: this.addCaseDesc}).then(() => {
                    this.addCaseModal = false;
                    this.addCaseDesc = '';
                    this.getCasesByPage({page: 1, count: 25, text: '', ascending: {column: 'caseNumber', ascending: true}});
                    this.$mToast({
                        title: "Case Created" ,
                        style: 'success',
                    });
                });
            }
        },
        getReportResultsByPage(data) {
            this.searchResults = [];
            this.searchingIcon = true;
            let startDate, endDate;
            try {
                startDate = new Date(this.startDate).toISOString();
            } catch (e) {
                startDate = '';
            }
            try {
                endDate = new Date(this.endDate).toISOString();
            } catch (e) {
                endDate = '';
            }
            Report.getReportResultsByPage({
                page: data.page,
                count: data.count,
                ascending: data.ascending,
                restrict: false,
                filterPREA: data.filterPREA,

                reportText: this.reportSearchText,
                template: this.searchTemplate,
                fields: JSON.stringify(this.searchTemplateFields),
                startDate: startDate,
                endDate: endDate
            }).then(response => {
                this.reportResultCount = response.data.total;
                this.searchResults = response.data.results;
                this.searchingIcon = false;
            });

        },
        getCasesByPage(data) {
            Report.getCasesByPage({
                page: data.page,
                count: data.count,
                text: data.text,
                ascending: data.ascending,
                restrict: false,
                filterPREA: data.filterPREA
            }).then(response => {
                this.user = response.data.user;
                if (response.data.user.role === 'admin' || response.data.user.role === 'PREA') {
                    this.userIsPREA = true;
                }
                this.caseCount = response.data.total;
                this.cases = response.data.results;
            });
        },
    }
}
</script>

<style scoped>
.input-height {
    height: inherit;
}
</style>