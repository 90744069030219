<template>
    <div>
        <!-- Main Body -->
        <b-row>

            <!-- Cases Table -->
            <b-col lg="6">
                <b-card style="height: calc(100vh - 200px);" no-body>
                    <div slot="header">
                        <div class="d-flex w-100 justify-content-between align-items-baseline">
                            <h5 style="margin-bottom: 0">
                                <b>Assigned Cases</b> <span>&nbsp;</span>
                                <fa-icon :icon="['fas', 'circle-question']" @mouseenter="toggleInfoBox = true"
                                         @mouseleave="toggleInfoBox = false"/>
                                <span v-if="toggleInfoBox === true" class="infoBox">
                                    <span class="infoTitle">Red Case Numbers</span>
                                    <span class="infoText">{{ redCaseNumberInfo }}</span><br><br>
                                </span>
                            </h5>
                        </div>
                    </div>
                    <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                        <m-table :itemPerPageOptions="itemPerPageOptions"
                                 :itemCount="caseCount"
                                 :tableColumns="caseOptions.columns"
                                 :tableOptions="caseOptions"
                                 :items="cases"
                                 @getItemsByPage="getCasesByPage">
                            <template v-slot:caseNumber="props">
                                <router-link :to="{ name: 'Case', params: {caseId: props.props._id}}"
                                             :style="completedCase(props.props.reports)"
                                             style="color: inherit"
                                             title="Go To Case">
                                    {{ props.props.caseNumber }}
                                </router-link>
                            </template>
                        </m-table>
                    </div>
                </b-card>
            </b-col>

            <!-- Reports Table -->
            <b-col lg="6">
                <b-card style="height: calc(100vh - 200px); overflow-y: hidden;" no-body>
                    <div slot="header" class="d-flex w-100 justify-content-between">
                        <h5 style="margin-bottom: 0"><b>Reports</b></h5>
                    </div>
                    <m-tabs :tabs="tabs" v-model="defaultTab" style="margin: 0 20px 20px 20px; flex: 1" @beforeTabChange="changeTab">
                        <div slot="Your Reports">
                            <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                                <m-table :itemPerPageOptions="itemPerPageOptions"
                                         :itemCount="reportCount"
                                         :tableColumns="reportOptions.columns"
                                         :tableOptions="reportOptions"
                                         :items="reports"
                                         @getItemsByPage="getReportsByPage">
                                    <template v-slot:reportNumber="props">
                                        <router-link
                                            :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}"
                                            style="color: inherit" title="Go To Report">
                                            {{ props.props.reportNumber }}
                                        </router-link>
                                    </template>
                                </m-table>
                            </div>
                        </div>
                        <div slot="Approved Reports">
                            <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                                <m-table :itemPerPageOptions="itemPerPageOptions"
                                              :itemCount="reportCount"
                                              :tableColumns="reportOptions.columns"
                                              :tableOptions="reportOptions"
                                              :items="reports"
                                              @getItemsByPage="getApprovedReportsByPage">
                                    <template v-slot:reportNumber="props">
                                        <router-link target="_blank" :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}" style="color: inherit">
                                            {{props.props.reportNumber}}
                                        </router-link>
                                    </template>
                                    <template v-slot:user="props">
                                        <div>{{props.props.assigned_user.name}}</div>
                                    </template>
                                    <template v-slot:created_date="props">
                                        <div>{{new Date(props.props.created_date).toLocaleString()}}</div>
                                    </template>
                                    <template v-slot:approval_date="props">
                                        <div>{{new Date(props.props.approval_date).toLocaleString()}}</div>
                                    </template>
                                    <template v-slot:h__goToReport class="float-right">
                                        <span style="float: right; margin-bottom: 0">Go To Report</span>
                                    </template>
                                    <template v-slot:goToReport="props">
                                        <router-link target="_blank" :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}" style="color: inherit">
                                            <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; width: 29px"
                                                      size="sm"
                                                      title="Go To Report"
                                                      variant="dark">
                                                <fa-icon :icon="['fas', 'share']" style="margin-left: -2px;"/>
                                            </b-button>
                                        </router-link>
                                    </template>
                                </m-table>
                            </div>
                        </div>
                    </m-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2';
import report from '@/services/report';
import Multiselect from 'vue-multiselect';
import Vue from "vue";
import mTable from '@/components/m-client-table';
import mTabs from "@/components/m-tabs.vue";

Vue.use(ClientTable)


export default {
    name: "Cases",
    components: {
        Multiselect,
        mTable,
        mTabs
    },
    data() {
        return {
            cases: [],
            reports: [],
            caseCount: 0,
            reportCount: 0,
            toggleInfoBox: false,
            itemPerPageOptions: [
                {text: 10, value: 10},
                {text: 25, value: 25},
                {text: 50, value: 50},
                {text: 100, value: 100},
            ],
            caseOptions: {
                columns: ['caseNumber', 'description', 'status'],
                sortable: ['caseNumber', 'description', 'status'],
                headings: {
                    caseNumber: 'Case Number',
                    description: 'Description',
                    status: 'Status'
                },
                alwaysShowPerPageSelect: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {chunk: 5, edge: false, nav: 'scroll'},
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    caseNumber: function (ascending) {
                        return function (a, b) {
                            let aArray = a.caseNumber.split('-');
                            let bArray = b.caseNumber.split('-');
                            let aCase = aArray[1] + aArray[2];
                            let bCase = bArray[1] + bArray[2];
                            if (parseInt(aCase) < parseInt(bCase)) {
                                return ascending === true ? -1 : 1;
                            } else if (parseInt(aCase) > parseInt(bCase)) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    }
                }
            },
            reportOptions: {
                columns: ['reportNumber', 'status'],
                sortable: ['reportNumber', 'status'],
                headings: {
                    reportNumber: 'Report Number',
                    status: 'Status'
                },
                alwaysShowPerPageSelect: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {chunk: 5, edge: false, nav: 'scroll'},
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    reportNumber: function (ascending) {
                        return function (a, b) {
                            let aArray = a.reportNumber.split('-');
                            let bArray = b.reportNumber.split('-');
                            let aReport = aArray[1] + aArray[2];
                            let bReport = bArray[1] + bArray[2];
                            if (parseFloat(aReport) < parseFloat(bReport)) {
                                return ascending === true ? -1 : 1;
                            } else if (parseFloat(aReport) > parseFloat(bReport)) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    }
                }
            },
            redCaseNumberInfo: "Any case that is in red text, is a case that you are assigned to " +
                "that you have not made a report for.",
            theme: 'bootstrap4',
            user: {
                email: null,
                name: null,
                role: null,
                sub: null
            },
            tabs: [
                'Your Reports',
                'Approved Reports'
            ],
            defaultTab: 'Your Reports',
        }
    },
    async created() {
        await this.getCasesByPage({
            page: 1,
            count: 25,
            text: '',
            ascending: {column: 'caseNumber', ascending: true},
            restrict: true
        })
        await this.getReportsByPage({
            page: 1,
            count: 25,
            text: '',
            ascending: {column: 'reportNumber', ascending: true}
        })
    },
    methods: {
        async changeTab(oldName, newName) {
            if (newName === 'Your Reports') {
                await this.getReportsByPage({
                    page: 1,
                    count: 25,
                    text: '',
                    ascending: {column: 'reportNumber', ascending: true}
                })
            } else if (newName === 'Approved Reports') {
                await this.getApprovedReportsByPage({
                    page: 1,
                    count: 25,
                    text: '',
                    ascending: {column: 'reportNumber', ascending: true}
                })
            }
        },
        completedCase(reports) {
            for (const report of reports) {
                if (report.user === this.user.sub) {
                    return '';
                }
            }
            return 'color: #f86c6b;';
        },
        getCasesByPage(data) {
            report.getCasesByPage({
                page: data.page,
                count: data.count,
                text: data.text,
                ascending: data.ascending,
                restrict: true
            }).then(response => {
                this.user = response.data.user;
                this.caseCount = response.data.total;
                this.cases = response.data.results;
            });
        },
        getReportsByPage(data) {
            report.getReportsByPage({
                page: data.page,
                count: data.count,
                text: data.text,
                ascending: data.ascending
            }).then(response => {
                this.reportCount = response.data.total;
                this.reports = response.data.results;
            });
        },
        getApprovedReportsByPage(data) {
            report.getApprovedReportsByPage({
                page: data.page,
                count: data.count,
                text: data.text,
                ascending: data.ascending
            }).then(response => {
                this.reportCount = response.data.total;
                this.reports = response.data.results;
            });
        },
    },
}
</script>

<style scoped>
.infoBox {
    border-radius: 25px;
    position: absolute;
    width: 400px;
    background-color: #464646;
    z-index: 1;
    padding: 15px;
    border-color: #161616;
    border-style: solid;
    border-width: 5px;
}

.infoTitle {
    text-decoration: underline;
    display: flex;
    justify-content: center;
}

.infoText {
    font-size: 13px;
}
</style>